<template>
  <div id="depositFunds">
    <div class="content-box">
      <div class="inner">
        <component :is="currentComponent"></component>
        <div class="info-box">
          <ul v-html="$t('deposit.default.disclaimer', { platform: $config.info.fullName })"></ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    currentComponent() {
      return () => this.$config.pageLoader(this.regulator, 'DepositCps')
    },
  },
  mounted() {
    // 查询渠道列表
    this.$store.dispatch('payment/actionPaymentList')
    // 查询是否限制存款
    this.$store.dispatch('payment/actionRestrictDeposit')
  },
}
</script>

<style lang="scss" scoped>
@import '@/assets/css/pages/deposit/logo.scss';
@import '@/assets/css/pages/depositFunds.scss';
</style>
